import {
  CHILDREN,
  DIFF_COPY,
  DIFF_ORIGINAL,
  DISTRICT_BOUNDARIES,
  FOREGROUND,
  INTERSECTIONS,
  NEIGHBOURS,
  ORDERS,
  PARENTS,
  REON,
} from 'app/constants/layers';
import React from 'react';

import Map from './Map';

export const WithMapContextEgip = React.createContext({});

/**
 * Контекст карты.
 *
 * @param {React.Element} Component - Компонент.
 * @returns {React.Context} - Контекст карты.
 * @example useContext(WithMapContext);
 */
const withMap = (Component) => {
  //

  /**
   * Class MapProvider.
   *
   * @augments React.Component
   * @returns {JSX.ElementClass} - Жопа.
   */
  class MapProvider extends React.Component {
    //

    /**
     * Конструктор.
     *
     * @param {object} props - Пропсы.
     * @example - Жопа.
     */
    constructor(props) {
      super(props);

      this.state = {
        isMapLoaded: false,
      };
    }

    /**
     * Метод получения контекста карты.
     *
     * @returns {object} - Контекст карты.
     */
    getContextValue = () => {
      return {
        callMapFunction: this.callMapFunction,
        clearDiff: this.clearDiff,
        clearLayer: this.clearLayer,
        drawChildrenGeometry: this.drawChildrenGeometry,
        drawCurrentObjectsGeometry: this.drawCurrentObjectsGeometry,
        drawDiffCopyObjectGeometry: this.drawDiffCopyObjectGeometry,
        drawDiffOriginalObjectGeometry: this.drawDiffOriginalObjectGeometry,
        drawDistrictBoundaries: this.drawDistrictBoundaries,
        drawIntersectionsGeometry: this.drawIntersectionsGeometry,
        drawNeighboursGeometry: this.drawNeighboursGeometry,
        drawOrdersGeometry: this.drawOrdersGeometry,
        drawParentGeometry: this.drawParentGeometry,
        drawReonGeometry: this.drawReonGeometry,
        getGeometry: this.getGeometry,
        getRawGeometry: this.getRawGeometry,
        isMapLoaded: this.state.isMapLoaded,
        zoomToDiff: this.zoomToDiff,
      };
    };

    /**
     * Метод получения геометрии.
     *
     * @param {number} recordId - Рекорд ID.
     * @returns {object} - Возвращает геометрию.
     * @example ----
     */
    getGeometry = (recordId) => {
      const geometry = this.map.getGeometry(recordId);
      const result = {
        ...(geometry.points && {
          points: JSON.stringify(geometry.points),
        }),
        ...(geometry.polygons && {
          polygons: JSON.stringify(geometry.polygons),
        }),
        ...(geometry.lines && {
          lines: JSON.stringify(geometry.lines),
        }),
      };
      return result;
    };

    /**
     * Метод getRawGeometry.
     *
     * @param {number} recordId - Рекорд ID.
     * @returns {void} - Nothing.
     * @example ----
     */
    getRawGeometry = (recordId) => this.map.getGeometry(recordId);

    /**
     * Метод callMapFunction.
     *
     * @param {string} functionName - Имя функции.
     * @param {object} currentCard - Текущий паспорт.
     * @returns {void} - Nothing.
     * @example -----.
     */

    /**
     * Метод callMapFunction.
     *
     * @param {string} functionName - Имя функции.
     * @param {object} currentCard - Текущий паспорт.
     */
    callMapFunction = (functionName, currentCard) => {
      if (this.state.isMapLoaded) {
        this.map[functionName](
          currentCard && currentCard.record_id,
          currentCard && currentCard.type_id,
        );
      }
    };

    /**
     * Метод очистки слоя от геометрии.
     *
     * @param {number} layerId - ID слоя.
     * @returns {void} - Nothing.
     * @example -----
     */
    clearLayer = (layerId) => {
      if (this.state.isMapLoaded) {
        this.map.clearLayer(layerId);
      }
    };

    /**
     * Метод drawChildrenGeometry.
     *
     * @param {Array} items - Массив айтемов.
     * @returns {void} - Nothing.
     * @example ---
     */
    drawChildrenGeometry = (items) => {
      const itemsToDraw = items.map((item) => ({
        hint: item.hint,
        lines: item.lines,
        oghObjectId: item.id,
        oghTypeId: item.type_id,
        points: item.points,
        polygons: item.polygons,
      }));
      this.drawGeometryToLayer(itemsToDraw, CHILDREN);
    };

    /**
     * Метод drawCurrentObjectsGeometry.
     *
     * @param {Array} items - Массив айтемов.
     * @returns {void} - Nothing.
     * @example ---
     */
    drawCurrentObjectsGeometry = (items) => {
      const itemsToDraw = items.map((item) => ({
        hint: item.hint,
        lines: item.lines,
        oghObjectId: item.id,
        oghTypeId: item.type_id,
        points: item.points,
        polygons: item.polygons,
      }));
      this.drawGeometryToLayer(itemsToDraw, FOREGROUND, true);
    };

    /**
     * Метод drawDiffCopyObjectGeometry.
     *
     * @param {Array} items - Массив айтемов.
     * @returns {void} - Nothing.
     * @example ---
     */
    drawDiffCopyObjectGeometry = (items) =>
      this.drawGeometryToLayer(items, DIFF_COPY);

    /**
     * Метод drawDiffOriginalObjectGeometry.
     *
     * @param {Array} items - Массив айтемов.
     * @returns {void} - Nothing.
     * @example ---
     */
    drawDiffOriginalObjectGeometry = (items) =>
      this.drawGeometryToLayer(items, DIFF_ORIGINAL);

    /**
     * Метод drawGeometryToLayer.
     *
     * @param {object} objects - Жопа.
     * @param {*} layer - Жопа.
     * @param {boolean} autoZoom - Флаг зуминга.
     * @returns {void} - Nothing.
     * @example ---
     */
    drawGeometryToLayer = (objects, layer, autoZoom) => {
      if (this.map) {
        this.map.drawToLayer(layer, objects, autoZoom);
      }
    };

    /**
     * Метод drawIntersectionsGeometry.
     *
     * @param {Array} items - Массив айтемов.
     * @returns {void} - Nothing.
     * @example ---
     */
    drawIntersectionsGeometry = (items) => {
      const itemsToDraw = items.map((item) => ({
        // add oghTypeId to db output
        hint: item.hint,
        lines: item.lines,
        oghObjectId: item.id,
        points: item.points,
        polygons: item.polygons,
      }));

      this.drawGeometryToLayer(itemsToDraw, INTERSECTIONS);
    };

    /**
     * Метод drawNeighboursGeometry.
     *
     * @param {Array} items - Массив айтемов.
     * @returns {void} - Nothing.
     * @example ---
     */
    drawNeighboursGeometry = (items) =>
      this.drawGeometryToLayer(items, NEIGHBOURS);

    /**
     * Метод drawOrdersGeometry.
     *
     * @param {Array} items - Массив айтемов.
     * @returns {void} - Nothing.
     * @example ---
     */
    drawOrdersGeometry = (items) => this.drawGeometryToLayer(items, ORDERS);

    /**
     * Метод drawParentGeometry.
     *
     * @param {Array} items - Массив айтемов.
     * @returns {void} - Nothing.
     * @example ---
     */
    drawParentGeometry = (items) => {
      const itemsToDraw = items.map((item) => ({
        hint: item.hint,
        lines: item.lines,
        oghObjectId: item.id,
        oghTypeId: item.type_id,
        points: item.points,
        polygons: item.polygons,
      }));
      this.drawGeometryToLayer(itemsToDraw, PARENTS, true);
    };

    /**
     * Метод drawDistrictBoundaries.
     *
     * @param {Array} items - Массив айтемов.
     * @returns {void} - Nothing.
     * @example ---
     */
    drawDistrictBoundaries = (items) =>
      this.drawGeometryToLayer(items, DISTRICT_BOUNDARIES, true);

    /**
     * Метод drawDistrictBoundaries.
     *
     * @param {Array} items - Массив айтемов.
     * @returns {void} - Nothing.
     * @example ---
     */
    drawReonGeometry = (items) => this.drawGeometryToLayer(items, REON);

    /**
     * Метод clearDiff.
     *
     * @returns {void} - Nothing.
     */
    clearDiff = () => {
      this.clearLayer(DIFF_COPY);
      this.clearLayer(DIFF_ORIGINAL);
    };

    /**
     * Метод clearLayer.
     *
     * @returns {void} - Nothing.
     */
    zoomToDiff = () => this.map.zoomToFeatures([DIFF_ORIGINAL, DIFF_COPY]);

    /**
     * Метод render.
     *
     * @returns {JSX.Element} - JSX.
     * @example - Жопа.
     */
    render() {
      return (
        <WithMapContextEgip.Provider value={this.getContextValue()}>
          <Component
            isMapLoaded={this.state.isMapLoaded}
            key="component"
            Map={(props) => (
              <Map
                key="map"
                ref={(map) => {
                  if (map) {
                    this.map = map;
                  }
                }}
                setIsMapLoaded={() => {
                  this.setState({ isMapLoaded: true });
                }}
                {...props}
              />
            )}
            {...this.props}
          />
        </WithMapContextEgip.Provider>
      );
    }
  }

  return MapProvider;
};

export default withMap;
