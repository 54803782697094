import { post } from 'app/api/crud/post';
import { getLengthGeometry } from 'app/pages/cardsOgh/api/helpers/getLengthGeometry';
import { getAttributeForm } from 'app/pages/cardsOgh/helpers/getAttributeForm';
import GeometryCSVLink from 'app/utils/card/checkGeometry/GeometryCSVLink';
import IntersectionsMessage from 'app/utils/card/checkGeometry/IntersectionsMessage';

/**
 * Post Intersection.
 *
 * @param {object} data - Data.
 * @returns {Promise<*>}
 */
export const postIntersection = async (data) =>
  await post('/ogh/geometry/intersection', data);

/**
 * Post Intersection.
 *
 * @param {object} geometry - Data.
 * @param {object} card - Data.
 * @param {object} values - Data.
 * @param {object} parentId - Data.
 * @returns {Promise<*>}
 */
export const postCheckIntersection = async (
  geometry,
  card,
  values,
  parentId,
) => {
  const data = {
    attribute: getAttributeForm(card.typeId, values),
    cnt: null,
    geometry,
    id: card?.rootId,
    journal: null,
    parentId: card.parentId,
    startDate: card.startDate,
    treeParentId: card.recordId ? card.recordId : Number(parentId),
    typeId: Number(card.typeId),
  };

  const res = await postIntersection(data);
  const length = getLengthGeometry(res);

  if (length) {
    throw Object({ errorKey: 'errorIntersections', res });
  }
  return 'Success';
};

/**
 * Create message for intersections.
 *
 * @param {object} geometries - Data.
 * @param {object} message - Data.
 * @returns {string}
 */
export function createIntersectionsMessage(geometries, message) {
  const intersections = getIntersections(geometries);
  const objGeoData = geometries.map(createObjGeoData);

  /**
   * A.
   *
   * @returns {string}
   */
  const GeometryCSV = () => (
    <GeometryCSVLink
      fileName={'IntersectionsGeometry.csv'}
      objects={objGeoData}
    />
  );

  return (
    <IntersectionsMessage
      GeometryCSVLink={GeometryCSV()}
      intersections={intersections}
      message={message}
      orderedList={false}
    />
  );
}

/**
 * Get intersections.
 *
 * @param {object} geometries - Data.
 * @returns {string}
 */
function getIntersections(geometries) {
  return geometries.slice(0, 10000000000).map((item) => {
    const { object_attribute_hint } = item.hint;
    const title = getHintValue(object_attribute_hint, 'title');
    const id_ogh = getHintValue(object_attribute_hint, 'id_ogh');
    const owner_name = getHintValue(object_attribute_hint, 'owner_name');

    if (owner_name) {
      return `${id_ogh}, ${title}, ${owner_name}`;
    }

    return `${id_ogh}, ${title}`;
  });
}

/**
 * Get hint value.
 *
 * @param {object} hint - Data.
 * @param {string} key - Data.
 * @returns {string}
 */
function getHintValue(hint, key) {
  const result = hint.find((item) => item.key === key);
  return result ? result.value : null;
}

/**
 * Create obj geo data.
 *
 * @param {object} root0 - Data.
 * @param {object} root0.points - Data.
 * @param {object} root0.lines - Data.
 * @param {object} root0.polygons - Data.
 * @param {object} root0.hint - Data.
 * @param {object} root0.hint.object_attribute_hint - Data.
 * @returns {object}
 */
function createObjGeoData({
  points,
  lines,
  polygons,
  hint: { object_attribute_hint },
}) {
  const newValue = {
    geometry: {
      lines,
      points,
      polygons,
    },
  };

  object_attribute_hint.forEach(function (item) {
    newValue[item.key] = item.value;
  });
  return newValue;
}
