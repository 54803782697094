import { Map, MapPointerEvent } from '@2gis/mapgl/types';
import { vertex } from 'app/services/mapService/pointMarkers';

import { GeometryUtils, sizeInPixelsToCoords } from '../../..';
import MapService from '../../../MapService';
import { isGeometryPolyline } from '../../../ShapeEditor';
import Shape from '../../../ShapeEditor/utils/Controls/Shape';
import {
  DrawerShapeOnChangeEvent,
  GenericCoordinates,
  GeometryTypes,
  RawGeometry,
} from '../../../types.d';

const CURSOR_TYPE = 'pointer';

/**
 * Вершина геометрии. На карте отображается точкой.
 * Может быть частью текущей геометрии и располагаться
 * на ее вершинах. Если частью не является, то отображается
 * либо при наведении на линию (или сторону полигона), либо
 * при рисовании новой геометрии под курсором.
 */
export default class Vertex {
  public isHovered: boolean = false;

  public geometryUtils: GeometryUtils;

  /**
   * Родительская геометрия.
   */
  public geometry: RawGeometry | null = null;

  /**
   * Координаты геометрии к которой принадлежит вершина.
   * Эти координаты могут быть либо для полигона, либо для дыры
   * в полигоне, либо для линии.
   */
  public geometryCoordinates: number[][] | number[][][];

  /**
   * Индекс подгеометрии в геометрии.
   */
  public subGeometryIndex: number;

  /**
   * Координаты вершины.
   */
  public coordinates: number[];

  /**
   * Является ли вершина частью текущей геометрии.
   * Если нет, то клик по нему создаст новую вершину,
   * и этот флаг будет установлен в true.
   */
  public isAppended: boolean = false;

  public shape: Shape<this>;

  public map: Map;

  /**
   * Индекс вершины в геометрии.
   * Если вершина не существует в геометрии, то
   * индекс будет равен одному из существующих индексов.
   * При создании новой вершины геометрии, смещаем индекс
   * последующих вершин на 1.
   */
  public index: number;

  public isVisible: boolean = false;

  private zoomEndHandler: () => void;

  /**
   * Конструктор.
   *
   * @param geometry - Родительская геометрия.
   * @param geometryCoordinates - Координаты геометрии к которой принадлежит вершина.
   * @param coordinates - Координаты вершины.
   * @param map - Map.
   * @param index - Индекс вершины в геометрии.
   * @param subGeometryIndex - Индекс подгеометрии в геометрии.
   * @param mapService - Сервис для работы с картой.
   * @param geometryUtils - Утилиты для геометрии.
   * @param state - Состояние вершины.
   * @param state.isAppended - Является ли вершина частью текущей геометрии.
   * @param state.isVisible - Является ли вершина видимой.
   * @returns Vertex.
   */
  constructor(
    geometry: RawGeometry | null = null,
    geometryCoordinates: number[][],
    coordinates: number[],
    map: Map,
    index: number,
    subGeometryIndex: number,
    mapService: MapService,
    geometryUtils: GeometryUtils,
    state?: {
      isAppended?: boolean;
      isVisible?: boolean;
    },
  ) {
    const iconSize: [number, number] = [12, 12];

    this.geometryUtils = geometryUtils;
    this.geometry = geometry;
    this.geometryCoordinates = geometryCoordinates;
    this.coordinates = coordinates;
    this.map = map;
    this.index = index;
    this.subGeometryIndex = subGeometryIndex;

    this.isAppended = !!state?.isAppended;
    this.isVisible = !!(state?.isVisible ?? true);

    this.shape = new Shape(map, {
      anchor: [6.5, 8],
      coordinates,
      cursor: CURSOR_TYPE,
      icon: vertex,
      iconSize,
      mapService,
      size: iconSize,
      userData: {
        control: this,
        coordinates,
        coordinatesToCheckMouseEvent: sizeInPixelsToCoords(
          map,
          coordinates,
          iconSize,
        ),
        type: GeometryTypes.Point,
      },
    });

    if (!this.isVisible) {
      this.shape.hide();
    }

    this.zoomEndHandler = this.onZoomEnd.bind(this);

    map.on('zoomend', this.zoomEndHandler);
  }

  /**
   * Обработчик изменения размера геометрии
   * после того, как был зум был завершен.
   *
   * @returns This.
   */
  public onZoomEnd() {
    this.shape.updateCoordinatesToCheckMouseEvent(this.coordinates);
    return this;
  }

  /**
   * Обработчик перемещения вершины.
   *
   * @param {DrawerShapeOnChangeEvent} ev - Аргументы.
   * @param ev.geometry - Вся геометрия.
   * @param ev.coordinates - Новые координаты вершины.
   * @returns GenericCoordinates.
   */
  public onChange({ coordinates }: DrawerShapeOnChangeEvent) {
    this.shape.updateCoordinates({ lngLat: coordinates });
    if (this.geometry && this.isAppended) {
      this.geometry.destroy();
    }

    if (this.isAppended) {
      return this.handleAppendedChange({ coordinates });
    }

    return this.geometry?.userData.coordinates || [];
  }

  /**
   * Добавление вершинe к подгеометрии.
   *
   * @param geometry - Родительская геометрия.
   * @param geometryCoordinates - Координаты подгеометрии.
   * @param extraArgs - Дополнительные аргументы.
   * @param extraArgs.index - Индекс вершины в подГеометрии.
   * @param extraArgs.subGeometryIndex - Индекс подгеометрии в геометрии.
   * @param extraArgs.coordinates - Координаты вершины.
   * @returns GenericCoordinates.
   */
  public append(
    geometry: RawGeometry,
    geometryCoordinates: number[][] | number[][][],
    {
      index,
      subGeometryIndex,
      coordinates,
    }: {
      index?: number;
      subGeometryIndex?: number;
      coordinates?: number[];
    } = {},
  ) {
    this.isAppended = true;
    this.index = index ?? this.index;
    this.subGeometryIndex = subGeometryIndex ?? this.subGeometryIndex;
    this.geometry = geometry;
    this.geometryCoordinates = geometryCoordinates;
    this.coordinates = coordinates ?? this.coordinates;

    return this.handleAppendedChange(
      { coordinates: this.coordinates },
      this.isAppended,
    );
  }

  /**
   * Установка курсора.
   *
   */
  public setCursorStyle() {
    this.shape.setCursorStyle(CURSOR_TYPE);
  }

  /**
   * Сброс курсора.
   *
   */
  public resetCursorStyle() {
    this.shape.setCursorStyle(null);
  }

  /**
   * Удаление вершины.
   *
   */
  public destroy() {
    this.shape.destroy();
    this.map.off('zoomend', this.zoomEndHandler);

    this.resetFields();
  }

  /**
   * Удаление вершины.
   *
   * @param shouldDestroy - Удалять ли маркер вершины.
   * @returns GenericCoordinates.
   */
  public remove(shouldDestroy: boolean = true) {
    const { geometry, geometryCoordinates, index, subGeometryIndex } = this;

    this.resetFields();

    if (geometry) {
      const fullGeometryCoordinates = geometry.userData.coordinates;

      if (isGeometryPolyline(geometry)) {
        return [
          ...fullGeometryCoordinates.slice(0, index),
          ...fullGeometryCoordinates.slice(index + 1),
        ];
      } else {
        const subGeometryCoordinates = geometryCoordinates[
          subGeometryIndex
        ] as number[][];
        let newSubGeometryCoordinates: number[][] = [];

        const isOneSidePolygon =
          subGeometryCoordinates.length === 4 &&
          subGeometryCoordinates[1][0] === subGeometryCoordinates[2][0] &&
          subGeometryCoordinates[1][1] === subGeometryCoordinates[2][1];
        if (!isOneSidePolygon && subGeometryCoordinates.length > 3) {
          newSubGeometryCoordinates = [
            ...subGeometryCoordinates.slice(0, index),
            ...subGeometryCoordinates.slice(index + 1),
          ];

          if (index === 0) {
            newSubGeometryCoordinates[newSubGeometryCoordinates.length - 1] =
              newSubGeometryCoordinates[0];
          }

          if (newSubGeometryCoordinates.length < 4) {
            newSubGeometryCoordinates = [
              newSubGeometryCoordinates[0],
              newSubGeometryCoordinates[1],
              newSubGeometryCoordinates[1],
              newSubGeometryCoordinates[0],
            ];
          }
        }

        return [
          ...fullGeometryCoordinates.slice(0, subGeometryIndex),
          newSubGeometryCoordinates,
          ...fullGeometryCoordinates.slice(subGeometryIndex + 1),
        ].filter(
          (subGeometryCoords) =>
            !!(
              subGeometryCoords && (subGeometryCoords as number[][]).length > 3
            ),
        );
      }
    }

    if (shouldDestroy) {
      this.destroy();
    }

    return [];
  }

  /**
   * Установка видимости вершины.
   *
   * @param isVisible - Видимость.
   */
  public setIsVisible(isVisible: boolean) {
    this.isVisible = isVisible;
    if (isVisible) {
      this.shape.show();
    } else {
      this.shape.hide();
    }
  }

  /**
   * Устанавливает, находится ли курсор мыши на вершине.
   *
   * @param {MapPointerEvent} event - Событие указателя карты.
   * @param event.lngLat - Координаты указателя карты.
   * @returns Возвращает true, если курсор находится над вершиной, иначе false.
   */
  public isMouseHovered({ lngLat }: MapPointerEvent) {
    this.isHovered = this.geometryUtils.isPointWithinPolygon(
      lngLat,
      this.shape.coordinatesToCheckMouseEvent,
    );
    return this.isHovered;
  }

  /**
   * Сброс полей.
   *
   */
  private resetFields() {
    this.isAppended = false;
    this.index = -1;
    this.subGeometryIndex = -1;
    this.geometry = null;
    this.geometryCoordinates = [];
    this.coordinates = [0, 0];
  }

  /**
   * Обработчик изменения геометрии или подгеометрии.
   *
   * @param event - Событие.
   * @param event.coordinates - Новые координаты вершины.
   * @param shouldAppend - Признак замены вершины.
   * @returns A.
   */
  private handleAppendedChange(
    { coordinates }: DrawerShapeOnChangeEvent,
    shouldAppend = false,
  ) {
    const newCoordinates:
      | GenericCoordinates<GeometryTypes.Polyline>
      | GenericCoordinates<GeometryTypes.Polygon> = [];
    const { geometry, geometryCoordinates, index, subGeometryIndex } = this;

    if (geometry) {
      const fullGeometryCoordinates = geometry.userData.coordinates;

      if (isGeometryPolyline(geometry)) {
        if (index === 0) {
          return [coordinates, ...fullGeometryCoordinates.slice(1)];
        }

        return [
          ...fullGeometryCoordinates.slice(0, index),
          coordinates,
          ...fullGeometryCoordinates.slice(shouldAppend ? index : index + 1),
        ];
      } else {
        const subGeometryCoordinates = geometryCoordinates[subGeometryIndex];
        const newSubGeometryCoordinates = [
          ...subGeometryCoordinates.slice(0, index),
          coordinates,
          ...subGeometryCoordinates.slice(shouldAppend ? index : index + 1),
        ];

        if (index === 0) {
          newSubGeometryCoordinates[newSubGeometryCoordinates.length - 1] =
            coordinates;
        }

        return [
          ...fullGeometryCoordinates.slice(0, subGeometryIndex),
          newSubGeometryCoordinates,
          ...fullGeometryCoordinates.slice(subGeometryIndex + 1),
        ];
      }
    }

    return newCoordinates;
  }
}
