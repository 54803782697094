import { MutableRefObject, useEffect } from 'react';

import { GeometryUtils } from '..';
import MapService from '../MapService';
import { Marker, Polygon, Polyline, RawGeometry } from '../types';

/**
 * Вешает обработчик событий движения мыши на карту, но функциональность заключается
 * в определении была ли мышь наведена на геометрию.
 *
 * @param mapUtils - Объект с методами и объектами для работы с картой.
 * @param mapUtils.isDisabled - Флаг отключения показа подсказки при клике.
 * @param mapUtils.mapService - Объект с методами для работы с картой.
 * @param mapUtils.onHoverHintElementRef - *.
 * @param mapUtils.geometryUtils - Объект с методами для работы с геометриями.
 */
export const useShowHintOnHover = ({
  isDisabled = false,
  mapService,
  onHoverHintElementRef,
  geometryUtils,
}: {
  isDisabled: boolean;
  mapService: MapService | null;
  onHoverHintElementRef: MutableRefObject<{
    closeHint: VoidFunction;
    element: HTMLDivElement | null;
    // eslint-disable-next-line
    setPosition(hoveredObject: RawGeometry | null, x: number, y: number): void;
  } | null>;
  geometryUtils: GeometryUtils | null;
}) => {
  useEffect(() => {
    if (!mapService) return;

    if (isDisabled) {
      const hoveredObject = mapService.hoveredObject;
      mapService?.setHoveredGeometries(null);
      mapService?.drawHovered(null, hoveredObject);
      onHoverHintElementRef.current?.closeHint();
      return;
    }

    if (mapService?.isMapLoaded) {
      // eslint-disable-next-line
      let [x, y] = [0, 0];
      const [coordsX, coordsY] = [0, 0];
      const currentHovered: Polygon | Polyline | Marker | null = null;

      /**
       * Устанавливает позицию для подсказки.
       *
       * @param evt - Объект события.
       */
      const setHintOnHoverPosition = (evt: MouseEvent) => {
        if (!geometryUtils) return;

        const { offsetX, offsetY } = evt;
        const hovered = mapService.hoveredObject;
        const clicked = mapService.clickedObject;
        const isHoveredEqualClicked =
          hovered && hovered.userData.id === clicked?.userData.id;
        if (!hovered || isHoveredEqualClicked) {
          // eslint-disable-next-line
          [x, y] = [0, 0];
        } else {
          // eslint-disable-next-line
          [x, y] = [offsetX, offsetY];
        }

        if (currentHovered) {
          onHoverHintElementRef.current?.setPosition(
            isHoveredEqualClicked ? null : hovered,
            // eslint-disable-next-line
            x,
            // eslint-disable-next-line
            y,
          );
        }

        // currentHovered = hovered;
        return;
      };

      /**
       * Обработчик.
       *
       */
      const mapMoveEventHandler = () => {
        setHintOnHoverPosition({
          offsetX: coordsX,
          offsetY: coordsY,
        } as MouseEvent);
      };

      mapService.map
        .getCanvas()
        .addEventListener('mousemove', setHintOnHoverPosition);
      mapService.map.getCanvas().addEventListener('move', mapMoveEventHandler);
      mapService.map
        .getCanvas()
        .addEventListener('zoomend', mapMoveEventHandler);
      return () => {
        mapService.map
          .getCanvas()
          .removeEventListener('mousemove', setHintOnHoverPosition);
        mapService.map
          .getCanvas()
          .removeEventListener('move', mapMoveEventHandler);
        mapService.map
          .getCanvas()
          .removeEventListener('zoomend', mapMoveEventHandler);
      };
    }
  }, [isDisabled, geometryUtils, mapService, onHoverHintElementRef]);
};
