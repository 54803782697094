import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { CardContainer } from 'app/pages/cardsOgh/components/CardContainer';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { NumberFieldRF } from 'core/form/reduxForm/fields/default/NumberField.RF';
import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';
import { TotalAreaGeoRF } from 'core/form/reduxForm/fields/prepared/textField/TotalAreaGeo.RF';
import React from 'react';

/**
 * Таб исходных данных реестра __Газоны__.
 *
 * @param {object} props - Пропсы.
 * @returns {React.ReactElement}
 */
const CharacteristicsTab = (props) => {
  const { stateGardening, lawnTypes, required } = props;

  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();
  const { editMode } = useMode();

  return (
    <CardContainer>
      <NumberFieldRF
        name={'section_num'}
        label={'№ участка'}
        disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
        required={required}
      />
      <SelectRF
        name={'lawn_type_id'}
        label={'Тип газона'}
        options={lawnTypes}
        disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
        required={required}
      />
      <SelectRF
        name={'state_gardening_id'}
        label={'Состояние'}
        options={stateGardening}
        disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
        required={required}
      />
      <NumberFieldRF
        digits={2}
        name={'total_lawn_area'}
        label={'Площадь, кв.м'}
        disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
        required={required}
      />
      <TotalAreaGeoRF disabled={true} decimalScale={2} />
      <NumberFieldRF
        digits={2}
        name={'lawn_area_without'}
        label={"Площадь за вычетом насаждений, кв.м'"}
        disabled={true}
        required={required}
      />
    </CardContainer>
  );
};

export default CharacteristicsTab;
