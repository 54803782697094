import { Map } from '@2gis/mapgl/types';
import { featureCollection, lineString } from '@turf/helpers';

import MapService from '../MapService';
import { PolylineOptions, UserData } from '../types';
import { GeometryTypes } from './../types';

/**
 * Обёртка для создания полилиний. Внутри использует GeoJson и слои карты.
 */
export class Polyline {
  public map: Map;

  public userData: UserData<GeometryTypes.Polyline>;
  private mapService: MapService;
  private sourceType: 'original' | 'editing' = 'original';

  /**
   * Идентификатор полилинии.
   *
   * @returns Number.
   */
  private get id() {
    return this.userData.id;
  }

  /**
   * Конструктор.
   *
   * @param map - Карта.
   * @param options - Опции для линии.
   */
  constructor(map: Map, options: PolylineOptions) {
    const { userData } = options;
    this.map = map;
    this.userData = userData;
    this.sourceType = userData.isEditing ? 'editing' : 'original';

    const { mapService } = userData;
    this.mapService = mapService;

    const { geoJsonPolylineSource } = this.mapService;

    const currentPolylines = mapService.getAllPolylines();

    const newPolylineFeatureCollection = featureCollection(
      currentPolylines
        .map((polyline) =>
          lineString(polyline.userData.coordinates, {
            id: polyline.userData.id,
            isChanging: polyline.userData.isChanging,
            isClicked: polyline.userData.isClicked,
            isEditing: polyline.userData.isEditing,
            isHovered: polyline.userData.isHovered,
            isSelected: polyline.userData.isSelected,
            layerType: polyline.userData.layerType,
            type: polyline.userData.type,
          }),
        )
        .concat(
          lineString(options.coordinates, {
            id: userData.id,
            isChanging: userData.isChanging,
            isClicked: userData.isClicked,
            isEditing: userData.isEditing,
            isHovered: userData.isHovered,
            isSelected: userData.isSelected,
            layerType: userData.layerType,
            type: userData.type,
          }),
        ),
    );

    geoJsonPolylineSource[this.sourceType].setData(
      newPolylineFeatureCollection,
    );
    mapService.addPolylines(this);
  }

  /**
   * Удаляет полилинию из карты.
   *
   */
  destroy() {
    const { geoJsonPolylineSource } = this.mapService;

    const currentPolylines = this.mapService.getAllPolylines();
    this.userData.areaOrPerimeterHint?.destroy();

    const newPolylineFeatureCollection = featureCollection(
      currentPolylines
        .filter((polyline) => polyline.userData.id !== this.id)
        .map((polyline) =>
          lineString(polyline.userData.coordinates, {
            isChanging: polyline.userData.isChanging,
            isClicked: polyline.userData.isClicked,
            isEditing: polyline.userData.isEditing,
            isHovered: polyline.userData.isHovered,
            isSelected: polyline.userData.isSelected,
            layerType: polyline.userData.layerType,
            type: polyline.userData.type,
          }),
        ),
    );

    geoJsonPolylineSource[this.sourceType].setData(
      newPolylineFeatureCollection,
    );

    this.mapService.removePolylines(this);
  }
}
