import MapglContextProvider from 'core/uiKit/components/DTW/contexts/MapglContextProvider';
import MapDTW from 'core/uiKit/components/DTW/Map';
import React from 'react';
export const WithMapContextDTW = React.createContext({});

/**
 * Контекст карты.
 *
 * @param {React.Element} Component - Компонент.
 * @returns {React.Context} - Контекст карты.
 * @example useMapglContext;
 */
const withMapDTW = (Component) => {

  /**
   * Class MapProvider.
   *
   * @augments React.Component
   * @returns {JSX.ElementClass} - Жопа.
   */
  class MapProvider extends React.Component {

    /**
     * Конструктор.
     *
     * @param {object} props - Пропсы.
     * @example - Жопа.
     */
    constructor(props) {
      super(props);

      this.state = {
        isMapLoaded: false,
      };
    }

    /**
     * Метод render.
     *
     * @returns {JSX.Element} - JSX.
     * @example - *.
     */
    render() {
      return (
        <MapglContextProvider>
          <Component
            isMapLoaded={this.state.isMapLoaded}
            key="component"
            Map={(props) => (
              <MapDTW
                key="map"
                ref={(map) => {
                  if (map) {
                    this.map = map;
                  }
                }}
                setIsMapLoaded={() => {
                  this.setState({ isMapLoaded: true });
                }}
                {...props}
              />
            )}
            {...this.props}
          />
        </MapglContextProvider>
      );
    }
  }

  return MapProvider;
};

export default withMapDTW;
