import { SERVIS_MAP } from 'config/env';
import { ReactNode } from 'react';

export type SERVIS = 'dtw' | 'egip';

/**
 * Тип, представляющий сопоставление сервисов с функциями.
 *
 * @template T_FUNCTION - Тип функции, ассоциированной с сервисом.
 */
export type ISwitchServisMap<T_FUNCTION> = Record<
  SERVIS,
  T_FUNCTION | ReactNode
>;

/**
 * Переключает сервис картографии.
 *
 * @param options - Опции.
 * @returns Выполнение кода.
 */
export const switchServisMap = <FUNCTION_MAP>(
  options: ISwitchServisMap<FUNCTION_MAP>,
) => {
  if (SERVIS_MAP in options) {
    return options[SERVIS_MAP as SERVIS];
  }
  return options.egip;
};
