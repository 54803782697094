import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import isMandatory from 'app/utils/card/isMandatory';
import { NumberFieldRF } from 'core/form/reduxForm/fields';
import React from 'react';

export const DISTANCE_NAME = 'distance';

/**
 * Number FieldRF __Длина, п.м__.
 *
 * @returns
 */
export const DistanceField = () => {
  const { typeId } = useGetCurrentCard();
  const { editMode } = useMode();
  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();
  return (
    <NumberFieldRF
      digits={1}
      name={DISTANCE_NAME}
      label={'Длина, п.м'}
      disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
      required={isMandatory(typeId, DISTANCE_NAME, true)}
    />
  );
};
