// 31 Газоны

import CardAbstract from 'app/components/card/common/CardAbstract';
import { getParseAttribute, parseDate } from 'app/components/card/common/parse';
import { objectTabs, Tabs } from 'app/components/card/common/tabs';
import {
  validateNumeric,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation';
import { IsDiffHeightMarkField } from 'app/components/card/ogh/components/fields/checkBoxFields/IsDiffHeightMark.Field';
import DocumentsTab, {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import { LAWN } from 'app/constants/oghTypes';
import { CardContainer } from 'app/pages/cardsOgh/components/CardContainer';
import getLawnTypes from 'app/selectors/card/getLawnTypes';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import getFormValues from 'app/selectors/form/getFormValues';
import getDict from 'app/selectors/getDict';
import { transformValues } from 'app/utils/form/transformation';
import { NoCalcRF } from 'core/form/reduxForm/fields/prepared/checkboxes/NoCalc.RF';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import printOghCardHeader from './header/oghHeader';
import CharacteristicsTab from './Lawn/components/CharacteristicsTab';
import submitCard from './submit';
import VersionsTemplate from './versions/VersionsTemplate';

const types = ['lawn'];
const Versions = VersionsTemplate(types);

const tabs = [
  {
    id: 'characteristics-tab',
    name: 'Исходные данные',
  },
  {
    id: 'docs-tab',
    name: 'Документы',
  },
];

/**
 * Классовый компонет LawnCard.
 */
class LawnCard extends React.Component {

  /**
   * Метод жизненого цикла render().
   *
   * @returns {JSX.Element} - Реакт компонент.
   * @example ----
   */
  render() {
    const { props } = this;
    const { card, mode, required, editMode } = props;
    const { type_id: typeId } = card;

    return (
      <CardAbstract>
        <div id="editor-card">
          {printOghCardHeader(card, mode)}
          {card && objectTabs(typeId)}
          <div id="props">
            <CardContainer>
              <ParentInfo />
              <NoCalcRF disabled={!editMode} />
              <IsDiffHeightMarkField />
            </CardContainer>
            <Tabs tabs={tabs} />
            <CharacteristicsTab
              editable={editMode}
              id="characteristics"
              lawnTypes={props.lawnTypes}
              required={required}
              stateGardening={props.stateGardening}
              typeId={typeId}
              values={props.currentValues}
            />

            {DocumentsTab()({
              documents: props.documents,
              editable: editMode,
              typeId,
            })}
          </div>
          <div hidden={true} id="versions">
            <Versions
              card={card}
              disabled={props.createMode}
              key={`versions-${card.root_id}`}
            />
          </div>
        </div>
      </CardAbstract>
    );
  }
}

const lawnDocumentsReducer = documentsReducer({
  oghGroupCode: 'yard_subobject',
});

const getStateGardening = getDict('stateGardening');

/**
 * Функция получения и установки изначальных значений из State.
 *
 * @param {object} state - Стейт.
 * @param {object} props - Пропсы.
 * @returns {object} - Поля.
 * @example ----
 */
const mapStateToProps = (state, props) => {
  const parse = getParseAttribute(props);
  const result = {
    createMode: isCreateMode(state, props),

    currentValues: getFormValues(state),

    editMode: isEditMode(state, props),
    enableReinitialize: true,
    initialValues: {
      endDate: parseDate(props.card.end_date),
      is_diff_height_mark: parse('is_diff_height_mark'),
      lawn_area_without: parse('lawn_area_without'),
      lawn_type_id: parse('lawn_type_id'),
      no_calc: parse('no_calc'),
      parent_name: props.card.parent_info,
      section_num: parse('section_num'),
      startDate: parseDate(props.card.start_date),
      state_gardening_id: parse('state_gardening_id'),
      total_lawn_area: parse('total_lawn_area'),
      totalAreaGeo: parse('total_area_geo'),
    },

    lawnTypes: getLawnTypes(state, props),
    onSubmit: onSubmit.bind({ props }),
    required: isRequired(state, props),

    stateGardening: getStateGardening(state),
    validate,
  };

  return lawnDocumentsReducer(result, state, props);
};

/**
 * Функция валидации.
 *
 * @param {object} values - Значения формы.
 * @returns {object} - Отвалидированные поля.
 * @example ----
 */
function validate(values) {
  return {
    ...validateNumeric(values, numericFields),
  };
}

/**
 * Функция создания атрибутов.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {object} - Возвращают атрибуты.
 * @example ----
 */
function createAttribute(formValues) {
  return {
    ...createDocumentsAttribute(formValues),
  };
}

const numericFields = [
  {
    name: 'total_lawn_area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'section_num',
    positive: true,
    type: 'decimal',
    zero: true,
  },
];

const mandatoryFields = {
  [LAWN]: [
    'section_num',
    'lawn_type_id',
    'state_gardening_id',
    'total_lawn_area',
  ],
};

/**
 * Функция сабмита.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {void} - Жопа.
 * @example ----
 */
export function onSubmit(formValues) {
  const values = { ...formValues };

  delete values.parent_name;

  const formValuesWithAttribute = {
    ...values,
    ...createAttribute(values),
  };

  validateRequiredFieldsAsync(
    formValuesWithAttribute,
    mandatoryFields[this.props.card.type_id],
  );

  submitCard(
    this.props,
    transformValues(formValuesWithAttribute, {
      numericFields,
    }),
  );
}

export default connect(mapStateToProps)(
  reduxForm({ form: 'editorCard' })(LawnCard),
);
