// 6 Опоры освещения и контактных сетей//

import CardAbstract from 'app/components/card/common/CardAbstract';
import { getParseAttribute, parseDate } from 'app/components/card/common/parse';
import { objectTabs, Tabs } from 'app/components/card/common/tabs';
import {
  validateNumeric,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation';
import { EndwiseField } from 'app/components/card/ogh/components/fields/numberFields/Endwise.Field/Endwise.Field';
import { OdhSideIdField } from 'app/components/card/ogh/components/fields/selectFields/OdhSideId.Field';
import { DescriptionField } from 'app/components/card/ogh/components/fields/textFields/Description.Field';
import { OdhAxisField } from 'app/components/card/ogh/components/fields/textFields/OdhAxis.Field';
import { PlacementIdRFWrapper } from 'app/components/card/ogh/components/selects/PlacementIdRFWrapper';
import DocumentsTab, {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import { CardContainer } from 'app/pages/cardsOgh/components/CardContainer';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import { mandatoryFields } from 'app/utils/card/isMandatory';
import { transformValues } from 'app/utils/form/transformation';
import { ConvElementTypeIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/ConvElementTypeId.RF';
import { MaterialIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/MaterialId.RF';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import printOghCardHeader from './header/oghHeader';
import submitCard from './submit';
import VersionsTemplate from './versions/VersionsTemplate';

const types = ['lampposts'];
const Versions = VersionsTemplate(types);

const tabs = [
  {
    id: 'characteristics-tab',
    name: 'Исходные данные',
  },
  {
    id: 'docs-tab',
    name: 'Документы',
  },
];

const propTypes = {
  card: PropTypes.object,
  createMode: PropTypes.bool,
  documents: PropTypes.array,
  editMode: PropTypes.bool,
  mode: PropTypes.object,
  required: PropTypes.bool,
};

/**
 * Компонент LamppostsCard.
 *
 */
class LamppostsCard extends React.Component {

  /**
   * Метод жизненного цикла рендер.
   *
   * @returns {React.ReactElement}
   */
  render() {
    const {
      mode,
      card,
      createMode,
      editMode,
      documents,
      isEditCurrentObjectIsExternalSystem,
    } = this.props;

    return (
      <CardAbstract>
        <div id="editor-card">
          {printOghCardHeader(card, mode)}
          {card && objectTabs(card.type_id)}

          <div id="props">
            <CardContainer>
              <ConvElementTypeIdRF
                withAll={false}
                disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
              />
              <ParentInfo />
            </CardContainer>

            <Tabs tabs={tabs} />

            <div id="characteristics">
              <CardContainer title={'Местоположение'}>
                <PlacementIdRFWrapper
                  disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
                  required={true}
                  withAll={false}
                />
                <OdhAxisField />
                <OdhSideIdField />
                <EndwiseField />
              </CardContainer>

              <CardContainer title={'Характеристики'}>
                <MaterialIdRF
                  disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
                />
              </CardContainer>
              <CardContainer>
                <DescriptionField />
              </CardContainer>
            </div>
            {DocumentsTab()({
              documents,
              editable: editMode,
              typeId: card.type_id,
            })}
          </div>
          <div hidden={true} id="versions">
            <Versions
              card={card}
              disabled={createMode}
              key={`versions-${card.root_id}`}
            />
          </div>
        </div>
      </CardAbstract>
    );
  }
}

LamppostsCard.propTypes = propTypes;

const dictFields = [
  'conv_element_type_id',
  'odh_side_id',
  'material_id',
  'placement_id',
];

const numericFields = [{ name: 'endwise', type: 'decimal', zero: true }];

/**
 * Функция валидации.
 *
 * @param {object} values - Значения формы.
 * @returns {*}
 */
const validate = (values) => validateNumeric(values, numericFields);

const lamppostsCardDocumentsReducer = documentsReducer({
  oghGroupCode: 'yard_subobject',
});

/**
 * Create attribute from form values.
 *
 * @param {*} formValues - Form values.
 * @returns {*}
 */
function createAttribute(formValues) {
  return {
    ...createDocumentsAttribute(formValues),
  };
}

/**
 * On submit handler.
 *
 * @param {*} formValues - Form values.
 * @returns {void}
 */
export function onSubmit(formValues) {
  const { card } = this.props;
  const values = { ...formValues };

  delete values.parent_name;

  const formValuesWithAttribute = {
    ...values,
    ...createAttribute(values),
  };
  validateRequiredFieldsAsync(
    formValuesWithAttribute,
    mandatoryFields[card.type_id],
  );
  submitCard(
    this.props,
    transformValues(formValuesWithAttribute, {
      dictFields,
      numericFields,
    }),
  );
}

/**
 * Функция mapStateToProps.
 *
 * @param {object} state - Состояние редакс.
 * @param {object} props - Пропсы.
 * @returns {*}
 */
const mapStateToProps = (state, props) => {
  const parse = getParseAttribute(props);

  const result = {
    createMode: isCreateMode(state, props),

    editMode: isEditMode(state, props),

    enableReinitialize: true,
    initialValues: {
      conv_element_type_id: parse('conv_element_type_id.conv_element_type'),
      customer_id: parse('customer_id'),
      description: parse('description'),
      endDate: parseDate(props.card.end_date),
      endwise: parse('endwise'),
      material_id: parse('material_id.material'),
      odh_axis: parse('odh_axis'),
      odh_side_id: parse('odh_side_id.odh_side'),
      owner_id: parse('owner_id'),
      parent_name: props.card.parent_info,
      placement_id: parse('placement_id.placement'),
      startDate: parseDate(props.card.start_date),
    },
    onSubmit: onSubmit.bind({ props }),

    required: isRequired(state, props),
    validate,
  };
  return lamppostsCardDocumentsReducer(result, state, props);
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'editorCard',
  })(LamppostsCard),
);
