import { CardContainer } from 'app/pages/cardsOgh/components/CardContainer';
import React from 'react';

interface TabsCardItemProps {
  children: React.ReactNode; // Use React.ReactNode to allow any renderable node

  /**
   * Ключ таба.
   */
  tabValue: string;

  /**
   * Стиль.
   */
  className?: string;
}

/**
 * Item Таба.
 *
 * @param {TabsCardItemProps} props - Пропсы.
 * @returns JSX.Element.
 */
export const TabsCardItem = ({
  children,
  tabValue,
  className,
}: TabsCardItemProps) => {
  return (
    <div className={className} id={tabValue}>
      <CardContainer>{children}</CardContainer>
    </div>
  );
};
