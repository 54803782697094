import NameField from './NameField';

/**
 * Mock Default.
 *
 * @returns {void}
 */
const mockDefault = () => {};

describe('🐛 spec NameField', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    mockDefault();

    // 🧹 clear mock

    //🔥 Act
    const res = NameField();

    //❓ Assert
    expect(res).toStrictEqual(expect.any(Function));
  });
});
