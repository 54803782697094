import {
  elementFormGrid,
  renderCustomTextField,
} from 'app/components/card/common/grid/index';

/**
 * Форма имени объекта.
 *
 * @returns {function(*): *}
 */
export default function () {
  const cols = 2;
  const title = '';
  const elements = getElements();
  return (props) =>
    elementFormGrid(elements(props), cols, title, props.card.type_id);
}

/**
 * Get elements.
 *
 * @returns {function(*): *}
 */
function getElements() {
  return (props) => [
    {
      ...defaultNameParams({
        ...props,
      }),
    },
    {
      ...defaultNameDescriptionParams({
        ...props,
      }),
    },
  ];
}

/**
 * Default name params.
 *
 * @returns {object}
 */
function defaultNameParams() {
  return {
    addition: { type: 'text' },
    component: renderCustomTextField,
    formValue: true,
    id: 're_calc_name',
    label: 'Наименование',
    name: 're_calc_name',
  };
}

/**
 * Default name description params.
 *
 * @param {object} root0 - Параметры.
 * @param {boolean} root0.editable - Режим редактирования.
 * @param {boolean} root0.required - Обязательное поле.
 * @returns {object}
 */
function defaultNameDescriptionParams({ editable, required }) {
  return {
    addition: { required: editable && required, type: 'text' },
    component: renderCustomTextField,
    editable,
    formValue: true,
    id: 'name_description',
    label: 'Уточнение наименования',
    name: 'name_description',
  };
}
