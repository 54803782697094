import TreesAndShrubsCard, {
  onSubmit,
} from 'app/components/card/ogh/trees_shrubs/trees_shrubs';
import { shallow } from 'enzyme';
import { SubmissionError } from 'redux-form';
import { vi } from 'vitest';

vi.mock('redux-form', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    reduxForm: vi.fn(() => (Component) => {
      return Component;
    }),
  };
});

vi.mock('react-redux', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    connect: vi.fn(() => (value) => {
      return value;
    }),
  };
});

describe('TreesShrubsJest', function () {
  it('Поле участка в режиме редактирования загружена не из внешней системы', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    const props = {
      card: {},
      editMode: true,
      isCurrentObjectIsExternalSystem: false,
      mode: {},
    };

    const wrapper = shallow(<TreesAndShrubsCard foo={123} {...props} />);

    const { disabled } = wrapper.find('#section_num').props();

    // Assert
    expect(disabled).toBeFalsy();
  });
  it('Поле участка в режиме просмотра загружена не из внешней системы', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    const props = {
      card: {},
      editMode: false,
      isCurrentObjectIsExternalSystem: false,
      mode: {},
    };

    const wrapper = shallow(<TreesAndShrubsCard foo={123} {...props} />);

    const { disabled } = wrapper.find('#section_num').props();

    // Assert
    expect(disabled).toBeTruthy();
  });
  it('Поле участка в режиме редактирования загружена из внешней системы', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    const props = {
      card: {},
      editMode: true,
      isCurrentObjectIsExternalSystem: true,
      mode: {},
    };

    const wrapper = shallow(<TreesAndShrubsCard foo={123} {...props} />);

    const { disabled } = wrapper.find('#section_num').props();

    // Assert
    expect(disabled).toBeTruthy();
  });
  it('Поле участка в режиме просмотра загружена из внешней системы', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    const props = {
      card: {},
      editMode: false,
      isCurrentObjectIsExternalSystem: true,
      mode: {},
    };

    const wrapper = shallow(<TreesAndShrubsCard foo={123} {...props} />);

    const { disabled } = wrapper.find('#section_num').props();

    // Assert
    expect(disabled).toBeTruthy();
  });
});

describe('TreesShrubs onsubmit', function () {
  it('test', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    const alert = vi.fn();

    const props = {
      alert,
      mandatoryFields: [],
    };
    // Act

    /**
     * Обертка для вызова onSubmit.
     *
     * @returns {void}
     */
    const callback = () => {
      onSubmit({}, (_) => _, props);
    };

    // Assert
    expect(callback).toThrow(SubmissionError);
    expect(alert).toBeCalledTimes(1);
  });
});
