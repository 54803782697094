import CardAbstract from 'app/components/card/common/CardAbstract';
import { elementFormGrid } from 'app/components/card/common/grid';
import Matching from 'app/components/card/common/table/Matching';
import { objectTabs } from 'app/components/card/common/tabs';
import { LandscapingTab } from 'app/components/card/ogh/ImprovementTerritoryCard/components/Landscaping.Tab';
import { PassportizationTab } from 'app/components/card/ogh/ImprovementTerritoryCard/components/Passportization.Tab';
import { CleanCategoryIdField } from 'app/components/card/ogh/Odh/components/CleanCategory/CleanCategoryIdField';
import { CleanSpCategoryIdField } from 'app/components/card/ogh/Odh/components/CleanCategory/CleanSpCategoryIdField';
import { CleanSubCategoryIdField } from 'app/components/card/ogh/Odh/components/CleanCategory/CleanSubCategoryIdField';
import { IntensityNumField } from 'app/components/card/ogh/Odh/components/CleanCategory/IntensityNumField';
import { Cleaning } from 'app/components/card/ogh/Odh/components/tabs/Cleaning';
import { EngineeringStructuresTab } from 'app/components/card/ogh/Odh/components/tabs/EngineeringStructures.Tab/EngineeringStructures.Tab';
import { MovementCharacteristics } from 'app/components/card/ogh/Odh/components/tabs/MovementCharacteristics';
import OrdersTab from 'app/components/card/ogh/OrdersTab';
import VersionsTemplate from 'app/components/card/ogh/versions/VersionsTemplate';
import { CUSTOMER_HINT } from 'app/constants/messages';
import { column } from 'app/constants/tables';
import { CardContainer } from 'app/pages/cardsOgh/components/CardContainer';
import { getYesterdayDateSting } from 'app/utils/date/getYesterdayDateSting';
import getHint from 'app/utils/getHint';
import {
  CreateTypeRF,
  CustomerOdhRF,
  IsDiffHeightMarkRF,
} from 'core/form/reduxForm/fields';
import KeyboardDatePickerRF from 'core/form/reduxForm/fields/default/datePickers/KeyboardDatePickerRF';
import { TextFieldRF } from 'core/form/reduxForm/fields/default/TextFieldRF';
import { IsAvdRF } from 'core/form/reduxForm/fields/prepared/checkboxes/IsAvd.RF';
import { DateSurveyRf } from 'core/form/reduxForm/fields/prepared/datePickers/DateSurvey.RF';
import { OwnerIdOdhRF } from 'core/form/reduxForm/fields/prepared/selects/autocompleteAsync/OwnerIdOdh.RF';
import { GrbsIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/GrbsId.RF';
import { TabPanel } from 'core/uiKit/components/TabPanel';
import { get } from 'lodash';
import React from 'react';

import printOghCardHeader from '../header/oghHeader';
import { RepairsTab } from '../ImprovementTerritoryCard/components/tabs/Repairs.Tab';
import { BasicParameters } from './components/tabs/BasicParameters/BasicParameters';
import { RepairsPlanTab } from './components/tabs/RepairsPlan.Odh.Tab';
import { TabsOdh } from './components/tabs/Tabs.Odh';
import { IsOrphanObjectRfField } from './fields/IsOrphanObjectRF.Field';
import { OdhDocsTab } from './Odh.Docs.Tab';

const types = ['odh'];
const Versions = VersionsTemplate(types);

const ImportedOrdersTab = OrdersTab();

const approvingTabStyles = {
  margin: '24px',
};

/**
 * ОДХ 1 Объект дорожного хозяйства.
 */
class OdhCard extends React.Component {

  /**
   * Метод жизненого цикла componentDidUpdate.
   *
   * @returns {void} - Nothing.
   * @example ------
   */
  componentDidMount() {
    const { editMode } = this.props;
    if (editMode) {
      this.handleIsOrphanObjectChange();
    }
  }

  /**
   * Метод жизненого цикла componentDidUpdate.
   *
   * @param {object} prevProps - Предыдущие пропсы.
   * @returns {void} - Nothing.
   * @example .
   */
  componentDidUpdate(prevProps) {
    const { change, departments, editMode } = this.props;
    const currentCustomer = get(this.props, 'currentValues.customer_id');
    const currentCustomerId = get(currentCustomer, 'id');
    const prevCustomer = get(prevProps, 'currentValues.customer_id.id');

    if (currentCustomerId !== prevCustomer) {
      if (!currentCustomerId) {
        change('grbs_id', null);
      } else {
        const currentGrbs = departments.find(
          (dict) => dict.id === get(currentCustomer, 'grbsid'),
        );

        change('grbs_id', currentGrbs && currentGrbs.id);
      }
    }

    if (editMode) {
      this.handleIsOrphanObjectChange();
      if (this.props.currentValues.is_orphan_object) {
        const {
          roadway_area = 0,
          margin_area = 0,
          footway_area = 0,
          roadway_noprkg_manual_clean_area = 0,
          roadway_noprkg_auto_clean_area = 0,
          roadway_prkg_manual_clean_area = 0,
          roadway_prkg_auto_clean_area = 0,
          margin_auto_clean_area = 0,
          margin_manual_clean_area = 0,
          auto_footway_area = 0,
          manual_footway_areastation = 0,
          manual_footway_area = 0,
          station_clean_area = 0,
          total_area,
          cleaning_area,
        } = this.props.currentValues;

        const calcAttributesTotalArea =
          Math.round((+roadway_area + +margin_area + +footway_area) * 100) /
          100;

        const calcAttributesCleaningArea =
          Math.round(
            (+roadway_noprkg_manual_clean_area +
              +roadway_noprkg_auto_clean_area +
              +roadway_prkg_manual_clean_area +
              +roadway_prkg_auto_clean_area +
              +margin_auto_clean_area +
              +margin_manual_clean_area +
              +auto_footway_area +
              +manual_footway_areastation +
              +manual_footway_area +
              +station_clean_area) *
              100,
          ) / 100;

        if (total_area !== calcAttributesTotalArea) {
          change('total_area', calcAttributesTotalArea);
        }

        if (cleaning_area !== calcAttributesCleaningArea) {
          change('cleaning_area', calcAttributesCleaningArea);
        }
      }
    }
  }

  /**
   * Метод.
   *
   * @returns {void} - Nothing.
   * @example .
   */
  handleIsOrphanObjectChange() {
    const {
      change,
      currentValues: { is_orphan_object, passport_date, actual_schema_date },
    } = this.props;
    if (is_orphan_object && (passport_date || actual_schema_date)) {
      change('passport_date', null);
      change('actual_schema_date', null);
    }
  }

  /**
   * Рендер.
   *
   * @returns {JSX.Element|null} - Возвращает либо компонент либо null.
   * @example .
   */
  render() {
    const { props } = this;
    const {
      // documents: docs,
      card,
      currentValues,
      editMode,
      isCurrentObjectIsExternalSystem,
      matching,
      matchingRegistryColumns,
      mode,
      required,
      selected,
    } = props;

    const isOrphanObject = !!currentValues.is_orphan_object;

    /**
     * Fn.
     *
     * @param {object} obj - Жопа.
     * @returns {null|*} - Жопа.
     * @example ----
     */
    const showIfIsNotOrphanObject = (obj) => (isOrphanObject ? null : obj);

    return (
      <CardAbstract>
        <div id="editor-card">
          {printOghCardHeader(card, props.mode)}
          {card && objectTabs(card.type_id)}
          <div id="props">
            {elementFormGrid(
              [
                <div>
                  <GrbsIdRF disabled={true} label={'Учредитель/ГРБС'} />
                </div>,
                <div>
                  <CreateTypeRF disabled={true} />
                </div>,

                showIfIsNotOrphanObject(
                  <div>
                    <OwnerIdOdhRF
                      helpTooltip={getHint('owner_id')}
                      disabled={
                        !(!isOrphanObject && editMode && !card.record_id)
                      }
                      required={true}
                    />
                  </div>,
                ),
                <div>
                  <CustomerOdhRF
                    helpTooltip={CUSTOMER_HINT}
                    disabled={!(editMode && !card.parent_info)}
                    required={true}
                  />
                </div>,
                {
                  addition: {
                    required,
                    type: 'text',
                  },
                  component: TextFieldRF,
                  editable: editMode,
                  formValue: true,
                  id: 'name',
                  label: 'Наименование',
                  name: 'name',
                },
                <div>
                  <IsDiffHeightMarkRF
                    label="Разновысотный ОГХ"
                    disabled={!editMode || isCurrentObjectIsExternalSystem}
                  />
                </div>,
              ],
              column.TWO,
              '',
              card.type_id,
            )}
            <TabsOdh
              propertyTab={props.propertyTab}
              onPropertyTab={props.onPropertyTab}
            />
            {/*Исходные данные*/}
            <TabPanel show={props.propertyTab === 'characteristics'}>
              <CardContainer title={'Классификационная характеристика объекта'}>
                <CleanCategoryIdField />
                <CleanSubCategoryIdField />
                <IntensityNumField />
                <CleanSpCategoryIdField />
              </CardContainer>
              {elementFormGrid(
                [
                  showIfIsNotOrphanObject({
                    addition: {
                      type: 'text',
                    },
                    component: TextFieldRF,
                    editable:
                      !isOrphanObject &&
                      editMode &&
                      !isCurrentObjectIsExternalSystem,
                    // disabled: is_orphan_object,
                    formValue: !isOrphanObject,
                    hidden: isOrphanObject,
                    id: 'passport_draft_org',
                    label:
                      'Исполнитель/Исполнители работ (по разработке, актуализации паспорта)',
                    name: 'passport_draft_org',
                  }),
                  showIfIsNotOrphanObject(
                    <div>
                      <KeyboardDatePickerRF
                        {...{
                          disabled: !(
                            !isOrphanObject &&
                            editMode &&
                            !isCurrentObjectIsExternalSystem
                          ),
                          label: 'Дата составления/согласования паспорта',
                          name: 'passport_date',
                          required: true,
                        }}
                      />
                    </div>,
                  ),
                  showIfIsNotOrphanObject({
                    addition: {
                      required: true,
                      type: 'text',
                    },
                    component: TextFieldRF,
                    editable: !isOrphanObject && editMode,
                    // disabled: is_orphan_object,
                    formValue: !isOrphanObject,
                    hidden: isOrphanObject,
                    id: 'pass_dev_initiator',
                    label: 'Инициатор разработки паспорта',
                    name: 'pass_dev_initiator',
                  }),
                  showIfIsNotOrphanObject(
                    <div>
                      <KeyboardDatePickerRF
                        {...{
                          disabled: !(
                            !isOrphanObject &&
                            editMode &&
                            !isCurrentObjectIsExternalSystem
                          ),
                          label: 'Дата актуализации плана-схемы',
                          maxDate: getYesterdayDateSting(),
                          name: 'actual_schema_date',
                        }}
                      />
                    </div>,
                  ),
                  <div>
                    <IsOrphanObjectRfField />
                  </div>,
                  <div>
                    <IsAvdRF disabled={!editMode} />
                  </div>,
                  showIfIsNotOrphanObject(
                    <div>
                      <DateSurveyRf
                        {...{
                          disabled: !(!isOrphanObject && editMode),
                          required: true,
                        }}
                      />
                    </div>,
                  ),
                ],
                column.TWO,
                'Данные паспорта',
                card.type_id,
              )}
              {showIfIsNotOrphanObject(
                elementFormGrid(
                  [
                    {
                      addition: {
                        type: 'text',
                      },
                      component: TextFieldRF,
                      editable: editMode,
                      formValue: true,
                      id: 'description',
                      label: 'Примечание',
                      name: 'description',
                      style: {
                        paddingBottom: 30,
                      },
                    },
                  ],
                  column.ONE,
                  '',
                  card.type_id,
                ),
              )}
            </TabPanel>

            {/*// Основные параметры*/}
            <BasicParameters propertyTab={props.propertyTab} />

            {/* Уборочные данные*/}
            <Cleaning propertyTab={props.propertyTab} />

            {/*// ХАРАКТЕРИСТИКИ ДВИЖЕНИЯ*/}
            <MovementCharacteristics propertyTab={props.propertyTab} />

            {/*ремонты*/}
            <TabPanel show={props.propertyTab === 'repairs'}>
              <RepairsTab />
            </TabPanel>

            {/*проекты*/}
            <TabPanel show={props.propertyTab === 'repairs_plan'}>
              <RepairsPlanTab />
            </TabPanel>

            {/*документы*/}

            <TabPanel show={props.propertyTab === 'docs'}>
              <OdhDocsTab />
            </TabPanel>

            <TabPanel show={props.propertyTab === 'orders'}>
              <ImportedOrdersTab
                {...this.props}
                propertyTab={props.propertyTab}
              />
            </TabPanel>

            {/*Инженерные сооружения для ТС*/}
            <EngineeringStructuresTab propertyTab={props.propertyTab} />
          </div>

          <div hidden={true} id="versions">
            <Versions
              card={card}
              disabled={props.createMode}
              key={`versions-${card.root_id}`}
            />
          </div>

          <div hidden={true} id="approving">
            <div style={approvingTabStyles}>
              <Matching
                card={card}
                columns={matchingRegistryColumns}
                data={matching}
                mode={mode}
                recordId={card.record_id}
                selected={selected}
              />
            </div>
          </div>
          <div hidden={true} id={'passportization'}>
            <PassportizationTab />
          </div>
          <LandscapingTab />
        </div>
      </CardAbstract>
    );
  }
}

export { OdhCard };
